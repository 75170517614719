import React, { useEffect, useState } from 'react'
import sitelogo from '../../images/sitelogo.png'
import { Link, useNavigate } from 'react-router-dom'
import { useLocation } from "react-router-dom";
import { Modal } from 'react-bootstrap'
import { useForm } from 'react-hook-form';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Tooltip } from 'antd';
import { useAuth } from '../../Context/AuthContext'
import AxiosInstance from '../../Api/AxiosInstance';
import { scrollToTop } from '../sections/ScrollToTop'
import Loader from '../Pages/Loader'
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";


const Header = () => {
    const { loggedIn, guestId, user, handleLogin } = useAuth();
    const location = useLocation();
    const { pathname } = location;
    const splitLocation = pathname.split("/");
    const [cartdata, setCartdata] = useState([])
    const navigate = useNavigate()
    const [show, setShow] = useState(false);
    const handleClose = () => { setShow(false); setFilterShow(false); window.location.reload() }
    const [cartModel, setCartModel] = useState(false)
    const [filterShow, setFilterShow] = useState(false)
    const [search, setSearch] = useState('');
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [category, setCategory] = useState([])
    const [count, setCount] = useState('')
    const [loading, setLoading] = useState(true);
    const [passwordType, setPasswordType] = useState("password");
    const [menu, setMenu] = useState(false)
    const [cart_total_details, setcart_Total_Details] = useState([])
    const userDetail = JSON.parse(user)
    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text");
            return true;
        }
        setPasswordType("password");
        return true;
    };

    const { register, handleSubmit, formState: { errors } } = useForm();

    const submit = async (data) => {
        try {
            const response = await AxiosInstance.post('login', { sponsor_id: data.sponsor_id, password: data.password })
            if (response.data.data.access_token) {
                localStorage.setItem("user", JSON.stringify(response.data.data));
                localStorage.setItem("token", response.data.data.access_token);
                handleLogin()
            }
            if (response.data.success == "1") {
                navigate("/checkout")
                window.location.reload()
                scrollToTop()
            }

        } catch (error) {
            console.log(error)
        }
    }

    let Subtotal = 0
    for (let i = 0; i < cartdata?.length; i++) {
        Subtotal += cartdata[i].sub_total
    }

    useEffect(() => {
        const fetchCategory = async () => {
            try {
                const response = await AxiosInstance.post("categories")
                setCategory(response.data.data)
            } catch (error) {
                console.log(errors)
            }
        }
        fetchCategory()
    }, [])

    const checkOut = () => {
        if (user) {
            navigate('/checkout')
            scrollToTop()
        } else {
            setShow(true)
        }
    }

    const logout = async () => {
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        navigate("/");
        scrollToTop();
        window.location.reload()
    };

    const fetchProduct = async () => {
        try {
            const response = await AxiosInstance.post("cart-lists")
            setCartdata(response?.data.data.items)
            setCount(response?.data.data)
            setcart_Total_Details(response?.data.data.cart_total_details)
            setLoading(false)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        fetchProduct()
        document.addEventListener('itemAddedToCart', fetchProduct);
        document.addEventListener('itemAddedToWishlist', fetchProduct);
        return () => {
            document.removeEventListener('itemAddedToCart', fetchProduct);
            document.removeEventListener('itemAddedToWishlist', fetchProduct);
        };
    }, [])


    const quantityCount = async (data) => {
        try {
            setLoading(true)
            await AxiosInstance.post("update-cart-lists", { cart_id: data[0], quantity: data[1] })
            fetchProduct()
        } catch (error) {
            console.log(error)
        }
    }

    const searchProduct = async (searchQuery) => {
        try {
            const response = await AxiosInstance.post("products")
            const data = response?.data.data;
            const filteredData = data.filter((item) => {
                return item?.title.toLowerCase().includes(searchQuery.toLowerCase());
            });
            searchQuery ? setFilteredProducts(filteredData) : setFilteredProducts('')
        } catch (error) {
            console.log(error)
        }
    }

    const handleSearchChange = (e) => {
        const searchQuery = e.target.value;
        setSearch(searchQuery.trim());
        searchProduct(searchQuery.trim());
    };


    return (
        <>
            <ToastContainer />
            <header className="header-area sticky-header header-transparent">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-6 col-lg-2 col-xl-2">
                            <div className="header-logo">
                                <Tooltip placement="bottom" title={"Smmartlife - Improving lives together!"} overlayStyle={{ maxWidth: '500px', paddingLeft: '10px' }}>
                                    <Link onClick={scrollToTop} to="/">
                                        <img className="logo-main" src={sitelogo} alt="Logo" />
                                    </Link>
                                </Tooltip>
                            </div>
                        </div>
                        <div className="col-lg-7 col-xl-7 d-none d-lg-block">
                            <div className="header-navigation ps-7">
                                <ul className="main-nav justify-content-start">
                                    <li className={splitLocation[1] === "" ? "active" : ""}><Link onClick={scrollToTop} to="/">Home</Link></li>
                                    <li className={splitLocation[1] === "about" ? "has-submenu active" : "has-submenu"}>
                                        <Link onClick={scrollToTop} to='/about'>About Us</Link>
                                    </li>
                                    <li className={splitLocation[1] === "shop" ? "has-submenu position-static active" : "has-submenu position-static"} >
                                        <Link onClick={scrollToTop} to='/shop'>Shop</Link>
                                        <ul className="submenu-nav-mega cate-mega-menu">
                                            <li>
                                                <Link onClick={scrollToTop} to="#" className="mega-title">Categories:</Link>
                                                <ul className="cate-mega-list">
                                                    {
                                                        category?.map((category) => (
                                                            <li key={category.id}>
                                                                <Link onClick={scrollToTop} to={`/product-category/${category?.slug}`}>{category?.name}</Link></li>
                                                        ))
                                                    }
                                                </ul>
                                            </li>
                                        </ul>
                                    </li>
                                    <li className={splitLocation[1] === "contact" ? "active" : ""}><Link onClick={scrollToTop} to="/contact">Contact Us</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-6 col-lg-3 col-xl-3">
                            <div className="header-action justify-content-end">
                                <button className="header-action-btn ms-0" type="button" data-bs-toggle="offcanvas" data-bs-target="#AsideOffcanvasSearch" aria-controls="AsideOffcanvasSearch">
                                    <span className="icon">
                                        <i className="fa fa-search"></i>
                                    </span>
                                </button>

                                <div className="header-action-btn wishlist-icon">
                                    <Link onClick={scrollToTop} to='/wishlist' className="fa fa-heart-o"></Link>
                                    <span className="cart-count">{count?.whishlist_count ? count?.whishlist_count : 0}</span>
                                </div>

                                <button className="header-action-btn" type="button" data-bs-toggle="offcanvas" data-bs-target="#AsideOffcanvasCart" aria-controls="AsideOffcanvasCart" onClick={() => setCartModel(true)}>
                                    <span className="icon">
                                        <i className="fa fa-shopping-cart"></i>
                                    </span>
                                    <span className="cart-count">{count?.cart_count ? count?.cart_count : 0}</span>
                                </button>

                                <div className="header-action-btn dropdown">
                                    <a className="dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                        <span className="icon" >
                                            <i className="fa fa-user"></i>
                                        </span>
                                    </a>
                                    {
                                        user ?
                                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                <li><Link className="dropdown-item" to='/myaccount' onClick={scrollToTop()}>My Account</Link></li>
                                            </ul> :
                                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                <li><Link className="dropdown-item" to='/login' onClick={scrollToTop()}>Login</Link></li>
                                                <li><Link className="dropdown-item" to='/register' onClick={scrollToTop()}>Register</Link></li>
                                            </ul>
                                    }
                                </div>



                                <button className="header-menu-btn" type="button"
                                    data-bs-toggle="offcanvas" data-bs-target="#AsideOffcanvasMenu" aria-controls="AsideOffcanvasMenu" >
                                    <span></span> <span></span> <span></span></button>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <Modal onHide={handleClose} show={show} size="md" centered>
                <button type="button" className="btn-close">
                    <span className="fa fa-close" onClick={handleClose}></span>
                </button>
                <section className="m-5">
                    <p className='text-20'>If you have shopped with us before, please enter your details below. If you are a new customer, please proceed to the Billing section.</p>
                    <div className="container">
                        <div className="row mb-n8">
                            <div className="col-lg-12 mb-8">
                                <div className="my-account-item-wrap">
                                    <h3 className="title">Login</h3>
                                    <div className="my-account-form">
                                        <form onSubmit={handleSubmit(submit)}>
                                            <div className="form-group mb-6">
                                                <label htmlFor="login_username">Sponsor ID <sup>*</sup></label>
                                                <input
                                                    type="text"
                                                    id="login_username"
                                                    placeholder='Sponsor ID'
                                                    {...register('sponsor_id', {
                                                        required: "Sponsor ID is required",
                                                    })}
                                                />
                                                <p className='mt-2' style={{ color: 'red', fontSize: '13px' }}>{errors.sponsor_id?.message}</p>
                                            </div>

                                            <div className="form-group mb-6 pwd-eye">
                                                <label htmlFor="login_pwsd">Password <sup>*</sup></label>
                                                <input
                                                    type={passwordType}
                                                    id="login_pwsd"
                                                    placeholder='Password'
                                                    {...register('password', {
                                                        required: "Password is required",
                                                        minLength: { value: 6, message: "Password must be at least 6 characters long" }
                                                    })}
                                                />
                                                <div onClick={() => togglePassword("password")}>
                                                    {passwordType === "password" ? (
                                                        <AiOutlineEye className='pass-view' />
                                                    ) : (
                                                        <AiOutlineEyeInvisible className='pass-view' />
                                                    )}
                                                </div>
                                                <p className='mt-2' style={{ color: 'red', fontSize: '13px' }}>{errors.password?.message}</p>
                                            </div>

                                            <div className="form-group d-flex align-items-center mb-14">
                                                <div className="form-check d-flex">
                                                    <input type="checkbox" className="form-check-input" id="remember_pwsd" />
                                                    <label className="form-check-label" htmlFor="remember_pwsd">Remember Me</label>
                                                </div>

                                                <button className="btn ml-auto" type="submit">Login</button>
                                            </div>
                                        </form>
                                        <div className="form-group">
                                            <Link className="lost-password" onClick={() => { scrollToTop(); setShow(false) }} to="/forgot_password"><u>Lost your Password?</u></Link>
                                        </div>
                                        <div className="form-group">
                                            <Link className="lost-password" onClick={() => { scrollToTop(); setShow(false) }} to="/register"><u>New to SmmartLife? Create an account</u></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </Modal>
            <aside className="aside-search-box-wrapper offcanvas offcanvas-top" tabIndex="-1" id="AsideOffcanvasSearch" aria-labelledby="offcanvasTopLabel">
                <div className="offcanvas-header">
                    <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"><i className="fa fa-close"></i></button>
                </div>
                <div className="offcanvas-body">
                    <div className="container pt--0 pb--0">
                        <div className="search-box-form-wrap">
                            <div className="search-note">
                                <p>Start typing and press Enter to search</p>
                            </div>
                            <form >
                                <div className="aside-search-form position-relative">
                                    <label htmlFor="SearchInput" className="visually-hidden">Search</label>
                                    <input id="SearchInput" type="search" className="form-control" placeholder="Search entire store…" value={search} onChange={handleSearchChange} />
                                    <button className="search-button" type="submit"><i className="fa fa-search"></i></button>
                                </div>
                            </form>
                            <div className='row search-product-list'>
                                {filteredProducts && filteredProducts?.map((item) => (
                                    <div className="col-lg-2 col-md-3 col-sm-4 col-xsm-6 col-6 items" key={item?.id}>
                                        <div className="aside-product-list-item">
                                            <a className='imgbox' onClick={scrollToTop} href={`/product/${item?.slug}`}>
                                                <img src={item?.file_url[0]} width="50" height="50" alt="" />
                                            </a>
                                            <a className='pro-title' onClick={scrollToTop} href={`/product/${item?.slug}`}>
                                                <span className="product-title">{item?.title}</span>
                                            </a>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </aside>

            <aside className="aside-cart-wrapper offcanvas offcanvas-end" id="AsideOffcanvasCart" aria-labelledby="offcanvasRightLabel">
                <div className="offcanvas-header">
                    <h1 className="d-none" id="offcanvasRightLabel">Shopping Cart</h1>
                    <button className="btn-aside-cart-close" data-bs-dismiss="offcanvas" aria-label="Close">Shopping Cart <i className="fa fa-chevron-right"></i></button>
                </div>
                {loading ?
                    <Loader />
                    : (
                        cartdata?.length !== 0 ?
                            <div className="offcanvas-body">
                                {cartdata && cartdata?.map((item, index) => (
                                    <ul className="aside-cart-product-list" key={index}>
                                        <li className="aside-product-list-item d-flex v-center">
                                            <Link onClick={() => quantityCount([item?.cart_id, 0])} className="remove">×</Link>
                                            <Link onClick={scrollToTop} to={`/product/${item?.slug}`}>
                                                <img src={item?.file_url[0]} width="68" height="84" alt="" />
                                            </Link>
                                            <div>
                                                <span className="product-title">{item?.title}</span>
                                                <span className="product-price d-block">{item?.quantity} × ₹{item?.item_price?.toFixed(2)}</span>
                                            </div>
                                        </li>
                                    </ul>
                                ))}
                                <p className="cart-total"><span>Subtotal:</span><span className="amount">₹{cart_total_details?.sub_total?.toFixed(2)}</span></p>
                                <Link onClick={() => { navigate('/cart'); scrollToTop() }} className="btn-total" data-bs-dismiss="offcanvas" >View cart</Link>
                                <Link onClick={checkOut} className="btn-total" data-bs-dismiss="offcanvas">Checkout</Link>
                            </div>
                            :
                            <div>
                                <br />
                                <h3 className='text-center'>Your cart is empty</h3>
                            </div>
                    )}
            </aside>


            <aside className="off-canvas-wrapper offcanvas offcanvas-start" tabIndex="-1" id="AsideOffcanvasMenu" aria-labelledby="offcanvasExampleLabel">
                <div className="offcanvas-header">
                    <h1 className="d-none" id="offcanvasExampleLabel">Aside Menu</h1>
                    <button className="btn-menu-close" data-bs-dismiss="offcanvas" aria-label="Close">menu <i className="fa fa-chevron-left"></i></button>
                </div>
                <div className="offcanvas-body">
                    <div id="offcanvasNav" className="offcanvas-menu-nav">
                        <ul>
                            <li><a className="offcanvas-nav-item" href="/">Home</a></li>
                            <li><a href="/about" >About Us</a></li>


                            {
                                menu ?
                                    <li className="offcanvas-nav-parent active" onClick={() => { setMenu(!menu) }}>
                                        <a className="offcanvas-nav-item" href="#">Shop</a>
                                        <ul>
                                            <li> <a onClick={scrollToTop} href={`/shop`}>All Categories</a></li>
                                            {
                                                category?.map((item) => (
                                                    <li key={item?.id}> <a onClick={scrollToTop} href={`/product-category/${item?.slug}`}>{item?.name}</a></li>
                                                ))
                                            }
                                        </ul>
                                    </li> :
                                    <li className="offcanvas-nav-parent" onClick={() => { setMenu(!menu) }}>
                                        <a className="offcanvas-nav-item" href="#">Shop</a>
                                        <ul>
                                            <li> <a onClick={scrollToTop} href={`/shop`}>Shop</a></li>
                                            {
                                                category?.map((item) => (
                                                    <li key={item?.id}> <a onClick={scrollToTop} href={`/product-category/${item?.slug}`}>{item?.name}</a></li>
                                                ))
                                            }
                                        </ul>
                                    </li>
                            }

                            <li><a className="offcanvas-nav-item" href="/contact">Contact</a></li>
                        </ul>
                    </div>
                </div>
            </aside>

        </>
    )
}

export default Header