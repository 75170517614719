import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import AxiosInstance from '../../Api/AxiosInstance';
import { scrollToTop } from '../sections/ScrollToTop'

const ThankYou = () => {

    const params = useParams()
    const [data, setData] = useState([])
    const [order_details, setOrder_details] = useState([])
    const [patment_history, setPayment_history] = useState([])


    const response = async () => {
        try {
            const response = await AxiosInstance.post("thank-you-details", { order_id: params.id })
            setData(response.data?.data)
            setOrder_details(response.data?.data?.order_details)
            setPayment_history(response.data?.data?.payment_history)
        } catch (error) {
            console.log(error)
        }

    }

    useEffect(() => {
        response()
    }, [])
    return (
            <div className="wrapper">
                <div className="blank_header trans"></div>
                <main className="main-content">
                    <nav aria-label="breadcrumb" className="breadcrumb-style1 pt-5 pb-5">
                        <div className="container">
                            <ol className="breadcrumb justify-content-center mb-0">
                                <li className="breadcrumb-item"><Link onClick={scrollToTop} to="/">Home</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Checkout</li>
                            </ol>
                        </div>
                    </nav>
                    <section className="thankyou-wrap section-space">
                        <div className="container">

                            <p className="thankyou-order-received"><strong> Thank you for shopping with us. Your transaction has been successfully completed. You can expect to receive your product within 5 to 7 days.</strong>
                            </p>

                            <ul className="thankyou-order-details order_details">
                                <li>Order Number: <strong>{data?.id}</strong></li>
                                <li>Date: <strong>{data?.order_date}</strong></li>
                                <li>Total: <strong>₹{data?.total_amount?.toFixed(2)}</strong></li>
                                <li>Payment Method: <strong>{patment_history.payment_method}</strong></li>
                            </ul>

                            <div className="woocommerce-order-details">
                                <h2 className="title">Order Details</h2>
                                <div className="shopping-cart-form table-responsive cart-table-content order-cart-table-block mb-0">
                                    <table className="table-order-details w-100">
                                        <thead>
                                            <tr>
                                                <th className="product-name">Product</th>
                                                <th width="140" className="product-total">Total</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                order_details?.map((data) => (
                                                    <tr className="order_item" key={data?.id}>
                                                        <td className="product-name">
                                                            <div > {data?.product_name} <strong className="product-quantity">×&nbsp;{data?.quantity}</strong></div>
                                                        </td>
                                                        <td className="product-total">
                                                            <span className="amount">₹{data?.sub_total?.toFixed(2)}</span>
                                                        </td>
                                                    </tr>
                                                ))
                                            }


                                        </tbody>

                                        <tfoot>
                                            <tr className="order-total">
                                                <td scope="row">Subtotal:</td>
                                                <td><strong><span className="amount">₹{data?.sub_total?.toFixed(2)}</span></strong></td>
                                            </tr>
                                            <tr className="order-total">
                                                <td scope="row">Shipping Charge</td>
                                                <td><strong>₹{data?.shipping_charge?.toFixed(2)}</strong></td>
                                            </tr>
                                            <tr className="order-total">
                                                <td scope="row">Tax</td>
                                                {
                                                    data?.igst ?
                                                        <td>IGST : <strong>₹{data?.igst?.toFixed(2)}</strong></td> :
                                                        <td>CGST : <strong>₹{data?.cgst?.toFixed(2)}</strong> <br/>
                                                            SGST : <strong> ₹{data?.sgst?.toFixed(2)}</strong>
                                                        </td>
                                                }
                                            </tr>
                                            <tr className="order-total">
                                                <td scope="row">Payment Method:</td>
                                                <td><strong>{patment_history.payment_method}</strong></td>
                                            </tr>
                                            <tr className="order-total">
                                                <td scope="row">Total:</td>
                                                <td><strong><span className="amount">₹{data?.total_amount?.toFixed(2)}</span></strong></td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
            </div>
    )
}

export default ThankYou
